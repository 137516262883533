import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { getContactInfo } from './api/contact';

function App() {
  const [loading, setLoading] = useState(true);
  const [contactInfo, setContactInfo] = useState();

  useEffect(() => {
    handleCall();
  }, []);

  const handleCall = async () => {
    try {
      const response = await getContactInfo();
      setContactInfo(response);
      console.log('response: ', response.data.attributes.phoneNumber);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className='App-link'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React
        </a>
        {!loading && (
          <>
            <h2>Contact info</h2>
            <p>Phone number: {contactInfo.data.attributes?.phoneNumber}</p>
            <p>Email: {contactInfo.data.attributes?.email}</p>
            <p>Second email: {contactInfo.data.attributes?.secondEmail}</p>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
